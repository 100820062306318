import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./Blog.scss";
import ButtonDark from "../../component/UI/Button";

const Blog = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [posts, setPosts] = useState([]);
  const [latestPost, setLatestPost] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPosts, setTotalPosts] = useState(0);
  const postsPerPage = 9;
  const selectedCategory = queryParams.get("category") || null;
  const currentPage = parseInt(queryParams.get("page"), 10) || 1;

  useEffect(() => {
    fetchPosts(selectedCategory, currentPage);
    fetchLatestPost();
    axios
      .get("https://blog-admin.craxionstudio.com/wp-json/wp/v2/categories")
      .then((response) => setCategories(response.data))
      .catch((error) => console.error("Error fetching categories:", error));
  }, [selectedCategory, currentPage]);

  const fetchPosts = (categoryId, page) => {
    setLoading(true);
    let url = `https://blog-admin.craxionstudio.com/wp-json/wp/v2/posts?per_page=${postsPerPage}&page=${page}&orderby=date&order=desc&_embed`;

    if (categoryId) {
      url += `&categories=${categoryId}`;
    }

    axios
      .get(url)
      .then((response) => {
        const postsWithFeaturedImages = response.data.map((post) => ({
          ...post,
          featuredImage:
            post._embedded?.["wp:featuredmedia"]?.[0]?.source_url || null,
          authorName: post._embedded?.author?.[0]?.name || "Unknown Author",
        }));

        setPosts(postsWithFeaturedImages);
        setTotalPosts(parseInt(response.headers["x-wp-total"], 10));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching posts:", error);
        setLoading(false);
      });
  };
  const fetchLatestPost = () => {
    axios
      .get(
        "https://blog-admin.craxionstudio.com/wp-json/wp/v2/posts?per_page=1&orderby=date&order=desc&_embed"
      )
      .then((response) => {
        if (response.data.length > 0) {
          const post = response.data[0];
          setLatestPost({
            ...post,
            featuredImage:
              post._embedded?.["wp:featuredmedia"]?.[0]?.source_url || null,
            authorName: post._embedded?.author?.[0]?.name || "Unknown Author",
          });
        }
      })
      .catch((error) => console.error("Error fetching latest post:", error));
  };

  const updateURL = (categoryId, page) => {
    const newParams = new URLSearchParams();
    if (categoryId) newParams.set("category", categoryId);
    newParams.set("page", page);
    navigate(`?${newParams.toString()}`);
  };

  const handleCategoryClick = (categoryId) => {
    updateURL(categoryId, 1);
  };

  const handlePageChange = (pageNumber) => {
    updateURL(selectedCategory, pageNumber);
  };

  const totalPages = Math.ceil(totalPosts / postsPerPage);

  return (
    <section id="blog" className="blog-section">
      <Helmet>
        <title>CraxZine - Blog & Inspirasi Digital</title>
        <meta
          name="description"
          content="Temukan konten keren dan ide-ide fresh yang bikin kamu terinspirasi di CraxZine!"
        />

        {/* Open Graph untuk Facebook & LinkedIn */}
        <meta
          property="og:title"
          content="CraxZine - Blog & Inspirasi Digital"
        />
        <meta
          property="og:description"
          content="Temukan konten keren dan ide-ide fresh yang bikin kamu terinspirasi di CraxZine!"
        />
        <meta
          property="og:image"
          content={
            latestPost?.featuredImage || "https://example.com/default.jpg"
          }
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="CraxZine - Blog & Inspirasi Digital"
        />
        <meta
          name="twitter:description"
          content="Temukan konten keren dan ide-ide fresh yang bikin kamu terinspirasi di CraxZine!"
        />
        <meta
          name="twitter:image"
          content={
            latestPost?.featuredImage || "https://example.com/default.jpg"
          }
        />

        {/* SEO Indexing */}
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="blog-hero">
        <div className="center">
          <h2 className="headline">CRAXZINE</h2>
          <p className="description">
            Temukan konten keren dan ide-ide fresh yang bikin kamu terinspirasi
            disini!
          </p>
        </div>
      </div>
      <div className="blog-top">
        {latestPost && (
          <div className="card-latest-news">
            <div className="latest-image">
              {latestPost.featuredImage && (
                <img
                  src={latestPost.featuredImage}
                  alt={latestPost.title.rendered}
                  className="img-latest-news"
                />
              )}
            </div>
            <div className="latest-description">
              <div className="desc-top">
                <small className="category">
                  {latestPost.categories.length > 0
                    ? categories.find(
                        (cat) => cat.id === latestPost.categories[0]
                      )?.name
                    : "Uncategorized"}
                </small>
                <h5 className="title">{latestPost.title.rendered}</h5>
              </div>
              <div className="desc-bottom">
                <div className="author-container">
                  <span className="date">
                    {new Date(latestPost.date).toLocaleDateString("id-ID")}
                  </span>
                  <address className="author">
                    By {latestPost.authorName}
                  </address>
                </div>
                <div className="latest-news-btn">
                  <a href={`/craxzine/${latestPost.slug}`}>
                    <ButtonDark name="Read More" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="blog">
        <ul className="categories">
          <li>
            <button
              onClick={() => handleCategoryClick(null)}
              className={!selectedCategory ? "active" : ""}
            >
              All
            </button>
          </li>
          {categories.map((category) => (
            <li key={category.id}>
              <button
                onClick={() => handleCategoryClick(category.id)}
                className={
                  selectedCategory === String(category.id) ? "active" : ""
                }
              >
                {category.name}
              </button>
            </li>
          ))}
        </ul>

        <div className="posts">
          {loading ? (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          ) : (
            <div className="posts-list">
              {posts.map((post) => (
                <div className="post-card" key={post.id}>
                  <div className="post-info">
                    <span className="category">
                      {post.categories.length > 0
                        ? categories.find(
                            (cat) => cat.id === post.categories[0]
                          )?.name
                        : "Uncategorized"}
                    </span>
                    <span className="date">
                      {new Date(post.date).toLocaleDateString("id-ID")}
                    </span>
                  </div>
                  <a
                    href={`/craxzine/${post.slug}`}
                    className="container-image"
                  >
                    {post.featuredImage && (
                      <img
                        className="post-image"
                        src={post.featuredImage}
                        alt="Post Featured"
                      />
                    )}
                    <a
                      href={`/craxzine/${post.slug}`}
                      className="card-read-more"
                    >
                      <ButtonDark name="Read More" />
                    </a>
                  </a>

                  <a href={`/craxzine/${post.slug}`} className="post-desc">
                    <p className="post-title">{post.title.rendered}</p>
                    <address className="post-author">
                      By {post.authorName}
                    </address>
                  </a>
                </div>
              ))}
            </div>
          )}

          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                disabled={currentPage === index + 1}
                className={`pagination-item ${
                  index + 1 === currentPage ? "active" : ""
                }`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
