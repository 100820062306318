import React from "react";
import "./AboutV2.scss";
import { ReactComponent as Arrowbtn } from "../../media/up-right.svg";
import meta from "../../media/about/meta.svg";
import tiktok from "../../media/about/tiktok.svg";
import googleads from "../../media/about/google-ads.svg";
import linkedin from "../../media/about/linkedin.svg";
import instagram from "../../media/about/instagram.svg";
import facebook from "../../media/about/facebook.svg";
import website from "../../media/about/website.svg";
import ReactPlayer from "react-player";

const About = () => {
  const icons = [
    { src: meta, alt: "meta" },
    { src: tiktok, alt: "tiktok" },
    { src: googleads, alt: "googleads" },
    { src: linkedin, alt: "linkedin" },
    { src: instagram, alt: "instagram" },
    { src: facebook, alt: "facebook" },
    { src: website, alt: "website" },
  ];

  return (
    <section className="about-container">
      <div className="container-center">
        <div className="container-img">
          <ReactPlayer
            url={require("../../media/lotties/Craxion Logo (2).mp4")} // Adjust the path to your video file
            playing={true} // Set to true if you want the video to play automatically
            controls={false}
            muted={true} // Show video controls
            width="100%" // Set width to 100% for responsiveness
            height="auto" // Set height to auto for responsiveness
            loop={true}
            autoPlay={true}
            config={{
              file: { tracks: [{ kind: "captions", label: "English" }] },
            }}
            playsinline={true}
          />
        </div>
        <div className="container-description">
          <div className="center">
            <div className="abouttitle">
              UBAH IDE JADI KENYATAAN BERSAMA KAMI!
            </div>
            <div className="aboutdes">
              Yuk, bikin gebrakan digital bareng! Dari pengembangan web,
              aplikasi kece, sampai manajemen media sosial yang bikin melongo.
              Hubungi kami dan wujudkan ide gilamu!
            </div>
            <div className="aboutlabel">Our Performance</div>
            <div className="abouttools">
              {icons.map((icon, index) => (
                <div className="tools-img" key={index}>
                  <img src={icon.src} alt={icon.alt} />
                </div>
              ))}
            </div>
            <div className="buttondiv">
              <button
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send?phone=6285810758395&text=Halo%20Saya%C2%A0ingin%20konsultasi%20mengenai%20website%20dan%20brand+activation%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
                    "_blank"
                  )
                }
                className="btn-welcome"
              >
                Learn More
                <div>
                  <Arrowbtn
                    width={16}
                    height={20}
                    fill="#FFFFFF"
                    className="primary-arrow"
                  />
                  <Arrowbtn
                    width={16}
                    height={20}
                    fill="#FFFFFF"
                    className="secondary-arrow"
                  />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
