const menuItems = [
  { id: 1, name: "Beranda", link: "/" },
  { id: 2, name: "Web Development", link: "/#webdevelopment" },
  { id: 3, name: "Brand Activation", link: "/#brandactivation" },
  { id: 4, name: "Apps Development", link: "/#appsdevelopment" },
  { id: 5, name: "CraxZine", link: "/craxzine" },
];
const footerItems = [
  { id: 1, name: "Beranda", link: "/" },
  { id: 2, name: "CraxZine", link: "/craxzine" },
];
const services = [
  { id: 1, name: "Web Development", link: "/#webdevelopment" },
  { id: 3, name: "Brand Activation", link: "/#brandactivation" },
  { id: 4, name: "Apps Development", link: "/#appsdevelopment" },
];

const contact = [
  {
    id: 1,
    name: "Instagram",
    link: "https://www.instagram.com/craxionstudio/",
  },
  { id: 2, name: "Tiktok", link: "https://www.tiktok.com/@craxionstudio" },
  { id: 3, name: "Whatsapp", link: "https://wa.me/6285810758395" },
  {
    id: 4,
    name: "Linkedin",
    link: "https://www.linkedin.com/company/craxionstudio/",
  },
  { id: 5, name: "Dribbble", link: "https://dribbble.com/craxionstudio" },
  { id: 6, name: "Behance", link: "https://www.behance.net/craxion" },
];
export { menuItems, footerItems, services, contact };
