import React, { useState, useEffect } from "react";
import "../navbar/Navbar.scss";
import logo from "../../media/logohd.webp";
import { ReactComponent as Arrowbtn } from "../../media/arrow.svg";
import { menuItems } from "../../component/sitemap-data.js";
import { ReactComponent as Plus } from "../../media/Vector.svg";
import { NavHashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
const Navbar = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  // Scroll ke atas jika berpindah halaman tanpa hash
  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname, location.hash]); // Tambahkan location.hash sebagai dependency
  return (
    <nav aria-label="Main Navigation">
      <div className="navbar-container">
        <div className="logo">
          <a href="/">
            <img
              src={logo}
              alt="Craxion - Build cool and diverse projects"
              width={"128px"}
            />
          </a>
          <div className="logo-desc">
            Bikin proyek keren dan beragam buat semua level ambisi.
          </div>
        </div>
        <div className={isOpen ? "nav-links active" : "nav-links"}>
          <div className="nav-menu">
            {menuItems.map((item, index) => {
              const isActive = currentPath === item.link; // Hanya halaman yang persis sama yang aktif

              return (
                <div key={index} className="nav-item-list">
                  <NavHashLink
                    onClick={toggleMenu}
                    to={item.link}
                    className={
                      "nav-item" + (isActive ? " nav-item-active" : "")
                    }
                    smooth
                  >
                    <div
                      className="inner-item"
                      role="button"
                      aria-pressed={isActive}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === "Space") {
                          e.preventDefault();
                          toggleMenu();
                        }
                      }}
                    >
                      {item.name}
                    </div>
                  </NavHashLink>
                </div>
              );
            })}

            <div
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send?phone=6285810758395&text=Halo%20Saya%C2%A0ingin%20konsultasi%20mengenai%20website%20dan%20brand+activation%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
                  "_blank"
                )
              }
              className="contact-btn"
              role="button"
              aria-label="Contact Us via Whatsapp"
            >
              <button className="inner-btn">
                Hubungi Kami
                <Arrowbtn width={23} height={23} className="arrow" />
              </button>
              <button className="outer-btn">
                Hubungi Kami <Arrowbtn width={23} height={23} fill="#ffffff" />
              </button>
            </div>
          </div>
        </div>
        <button
          className="menu-icon"
          onClick={toggleMenu}
          aria-label="Toggle Menu"
        >
          <i>Menu &nbsp;</i>
          <Plus className={isOpen ? "minus" : "plus"} />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
