import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./PostPage.scss"; // Import styling SCSS
import { ReactComponent as CopyIcon } from "../../media/share.svg";
import { ReactComponent as FacebookIcon } from "../../media/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../media/x.svg";
import { ReactComponent as LinkedinIcon } from "../../media/linkedin.svg";
import { Helmet } from "react-helmet-async";
const PostPage = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        setLoading(true);

        // Fetch post data by slug
        const { data: posts } = await axios.get(
          `https://blog-admin.craxionstudio.com/wp-json/wp/v2/posts?slug=${slug}&_embed`
        );

        if (posts.length === 0) {
          throw new Error("Post not found");
        }

        const postData = posts[0];

        // Extract Author Name
        const authorName =
          postData._embedded?.author?.[0]?.name || "Unknown Author";

        // Extract Categories
        const categories =
          postData._embedded?.["wp:term"]?.[0]?.map((cat) => cat.name) || [];

        // Extract Featured Image
        const featuredImage =
          postData._embedded?.["wp:featuredmedia"]?.[0]?.source_url || null;

        // Format Date
        const formattedDate = new Date(postData.date).toLocaleDateString(
          "id-ID"
        );

        // Update Post State
        setPost({
          ...postData,
          authorName,
          categories,
          featuredImage,
          date: formattedDate,
        });
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  // URL Post
  const postUrl = window.location.href;

  // Function untuk Copy URL
  const handleCopyUrl = () => {
    navigator.clipboard.writeText(postUrl);
    setCopySuccess(true);

    setTimeout(() => {
      setCopySuccess(false);
    }, 2000);
  };

  if (loading)
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    );

  if (error) return <div className="error-message">Error: {error}</div>;
  if (!post) return <div className="error-message">No post found.</div>;

  return (
    <section className="post-section">
      <Helmet>
        {/* Dynamic Title */}
        <title>{post.title.rendered} - CraxZine</title>

        {/* Meta Description */}
        {/* <meta
          name="description"
          content={post.excerpt.rendered.replace(/<[^>]+>/g, "")}
        /> */}

        {/* Open Graph (OG) for Facebook & LinkedIn */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={post.title.rendered} />
        {/* <meta
          property="og:description"
          content={post.excerpt.rendered.replace(/<[^>]+>/g, "")}
        /> */}
        <meta
          property="og:image"
          content={post.featuredImage || "https://example.com/default.jpg"}
        />
        <meta property="og:url" content={postUrl} />
        <meta property="og:site_name" content="CraxZine" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.title.rendered} />
        {/* <meta
          name="twitter:description"
          content={post.excerpt.rendered.replace(/<[^>]+>/g, "")}
        /> */}
        <meta
          name="twitter:image"
          content={post.featuredImage || "https://example.com/default.jpg"}
        />

        {/* Canonical URL */}
        <link rel="canonical" href={postUrl} />

        {/* SEO Indexing */}
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="post-container">
        <div className="container-meta">
          <h1 className="post-title">{post.title.rendered}</h1>
          <div className="post-meta">
            <div className="post-address">
              <span>{post.date}</span>
              <span>{post.categories.join(", ") || "No categories"}</span>
            </div>

            <p>{post.authorName}</p>
          </div>
        </div>
        {post.featuredImage && (
          <img
            className="post-image"
            src={post.featuredImage}
            alt={post.title.rendered}
          />
        )}
        <div
          className="post-content"
          dangerouslySetInnerHTML={{ __html: post.content.rendered }}
        />

        {/* Social Share Buttons */}
        <div className="post-bottom">
          <p>
            Article written by
            <span>{post.authorName}</span>
          </p>
          <div className="social-share">
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${postUrl}`}
              target="_blank"
              rel="noopener noreferrer"
              className="social-btn facebook"
            >
              <FacebookIcon />
            </a>
            <a
              href={`https://twitter.com/intent/tweet?url=${postUrl}&text=${post.title.rendered}`}
              target="_blank"
              rel="noopener noreferrer"
              className="social-btn twitter"
            >
              <TwitterIcon />
            </a>
            <a
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${postUrl}`}
              target="_blank"
              rel="noopener noreferrer"
              className="social-btn linkedin"
            >
              <LinkedinIcon />
            </a>
            <button onClick={handleCopyUrl} className="social-btn copy">
              {copySuccess ? <CopyIcon /> : <CopyIcon />}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PostPage;
