import "./Button.scss";
import { ReactComponent as Arrowbtn } from "../../media/up-right.svg";
const ButtonDark = (props) => {
  return (
    <button className="primary-button-dark">
      {props.name}
      <div>
        <Arrowbtn
          width={30}
          height={30}
          fill="#FFFFFF"
          className="primary-arrow"
        />
        <Arrowbtn
          width={30}
          height={30}
          fill="#FFFFFF"
          className="secondary-arrow"
        />
      </div>
    </button>
  );
};

export default ButtonDark;
